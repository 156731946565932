import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import Layout from "../components/blog-layout"
import SEO from "../components/seo"
import GetStartedBanner from "../components/get-started-footer"
import ShareButtons from "./../components/share-buttons"
// load css
import "./../styles/blog-interfaces.css"

// icons
import LeftArrowIcon from "../assets/icons/svg/arrow-point-to-left.svg"
import RightArrowIcon from "../assets/icons/svg/arrow-point-to-right.svg"

import Logo from "../assets/logo/logo.svg"

export default function BlogTemplate(props) {
  const { data, location } = props

  const { currentPost, nextPost, prevPost } = data

  const [isOpenMenu, setOpenMenu] = useState(false)
  const [sidebarWidth, setSidebarWidth] = useState(undefined)
  const [sidebarTop, setSidebarTop] = useState(undefined)

  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const [topInitialise, setTopInitialise] = useState(0)
  const [selectedHeadline, setSelectedHeadline] = useState(null)

  // const handleChange__openMobileSideBar = () => {
  // 	setOpenMenu(true)
  // }

  // const handleChange__closeMobileSideBar = () => {
  // 	setOpenMenu(false)
  // }

  const authorName = "Farouk Ben."

  const HOSTNAME = "https://odown.io"

  const blogTextContent = stripHtml(currentPost.html)

  useEffect(() => {}, [])

  return (
    <Layout>
      <SEO
        title={currentPost.frontmatter.title}
        description={currentPost.frontmatter.excerpt}
        author={authorName}
        pathname={currentPost.frontmatter.path}
        meta={[
          {
            name: `twitter:site`,
            content: `Odown_io`,
          },
          // {
          // 	name : `twitter:image:src`,
          // 	content : currentPost.frontmatter.featuredImage !==null ? HOSTNAME + currentPost.frontmatter.featuredImage.childImageSharp.fluid.src : ''
          // },
          // {
          // 	name : `og:image`,
          // 	content : currentPost.frontmatter.featuredImage !==null ? HOSTNAME + currentPost.frontmatter.featuredImage.childImageSharp.fluid.src : ''
          // },
          {
            name: `og:type`,
            content: "blog",
          },
          {
            name: `og:url`,
            content: `${HOSTNAME}${currentPost.frontmatter.path}`,
          },
          {
            name: `author`,
            content: authorName,
          },
          {
            name: `twitter:creator`,
            content: `@omarFBen`,
          },
        ]}
        image={{
          src:
            currentPost.frontmatter.featuredImage !== null
              ? currentPost.frontmatter.featuredImage.childImageSharp.fluid.src
              : null,
          width: 800,
          height: 400,
        }}
      />
      {/* {
				isOpenMenu === true 
				? <div className={`sidebar-close `} onClick={handleChange__closeMobileSideBar} >
					<CloseMobileMenuIcon/>
				</div>	
				: <div className={`sidebar-open `} onClick={handleChange__openMobileSideBar} >
					<OpenMobileMenuIcon/>
				</div>
			} */}
      <div className="blog-layout">
        <div className="container">
          <div className="blog-layout__content">
            <div className="layout-content__wrapper">
              <div className="article-breadcrumb margin-to-blog-left-side">
                <ul>
                  <li className="home">
                    <Link to={`/blog`}>Blog Home</Link>
                  </li>
                </ul>
              </div>
              <div className="article-body">
                <div className="article-body__content">
                  <div className="article-body__content-header margin-to-blog-left-side">
                    <h1>{currentPost.frontmatter.title}</h1>
                    <div className="author-wrapper">
                      <a
                        href="https://twitter.com/ben__farouk"
                        target="_blanc"
                        className="author-profile"
                      >
                        <img
                          className="author-photo"
                          src="https://pbs.twimg.com/profile_images/1678558460412141571/1q3BJajY_400x400.jpg"
                          alt="Farouk Ben. - Founder at Odown"
                        />
                        <span className="author-name">{authorName}</span>
                        <span className="post-readingtime">
                          (
                          {blogTextContent !== null
                            ? `${readingTime(blogTextContent)} minutes read`
                            : ``}
                          )
                        </span>
                      </a>
                      <div className="blog-post__date">
                        {moment(
                          currentPost.frontmatter.date,
                          "YYYY-MM-DD"
                        ).format("MMM DD, YYYY")}
                      </div>
                    </div>
                    {currentPost.frontmatter.featuredImage !== null && (
                      <div className="featued-image_wrapper">
                        <Img
                          className="featued-image_content"
                          fadeIn={false}
                          loading="eager"
                          fluid={
                            currentPost.frontmatter.featuredImage
                              .childImageSharp.fluid
                          }
                          alt={`${currentPost.frontmatter.title} - Odown - uptime monitoring and status page`}
                        />
                      </div>
                    )}
                  </div>
                  <div className="article-content-wrapper">
                    <div className="article-summarizer-wrapper">
                      <div className="article-summarizer">
                        {currentPost.frontmatter.summarizer !== null &&
                          currentPost.frontmatter.summarizer?.length > 0 && (
                            <>
                              <h6>Content</h6>
                              <div className="table-of-contents">
                                {currentPost.frontmatter.summarizer !== null ? (
                                  <ul>
                                    {currentPost.frontmatter.summarizer.map(
                                      (item, index) => {
                                        return (
                                          <li
                                            key={index}
                                            className={`${
                                              location.hash ===
                                              "#" + slugify(item)
                                                ? "selected"
                                                : ""
                                            }`}
                                          >
                                            <a href={`#${slugify(item)}`}>
                                              {item}
                                            </a>
                                          </li>
                                        )
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <div
                      className="article-content"
                      dangerouslySetInnerHTML={{ __html: currentPost.html }}
                    />
                  </div>

                  <div className="sharing-article-process margin-to-blog-left-side">
                    <label htmlFor="">Loved this post? Share it, please</label>
                    <ShareButtons
                      title={currentPost.frontmatter.title}
                      url={`${HOSTNAME}${currentPost.frontmatter.path}`}
                      twitterHandle={`Odown_io`}
                      tags={[]}
                    />
                  </div>
                </div>
              </div>
              {(prevPost !== null || nextPost !== null) && (
                <div className="articles-adjacent margin-to-blog-left-side">
                  <div className="prev-article">
                    {prevPost !== null && (
                      <Link
                        to={prevPost.frontmatter.path}
                        className="in-adjacent-wrapper"
                      >
                        <label className="direction-label" htmlFor="">
                          <LeftArrowIcon />
                          Previous Post
                        </label>
                        <div className="article-adjacent__featured-image">
                          {prevPost.frontmatter.featuredImage !== null && (
                            <div className="featued-image_wrapper">
                              <Img
                                className="featued-image_content"
                                fadeIn={false}
                                loading="eager"
                                fluid={
                                  prevPost.frontmatter.featuredImage
                                    .childImageSharp.fluid
                                }
                                alt={prevPost.frontmatter.title}
                              />
                            </div>
                          )}
                        </div>
                        <h3>{prevPost.frontmatter.title}</h3>
                      </Link>
                    )}
                  </div>
                  <div className="next-article">
                    {nextPost !== null && (
                      <Link
                        to={nextPost.frontmatter.path}
                        className="in-adjacent-wrapper"
                      >
                        <label className="direction-label" htmlFor="">
                          Next Post
                          <RightArrowIcon />
                        </label>
                        <div className="article-adjacent__featured-image">
                          {nextPost.frontmatter.featuredImage !== null && (
                            <div className="featued-image_wrapper">
                              <Img
                                className="featued-image_content"
                                fadeIn={false}
                                loading="eager"
                                fluid={
                                  nextPost.frontmatter.featuredImage
                                    .childImageSharp.fluid
                                }
                                alt={nextPost.frontmatter.title}
                              />
                            </div>
                          )}
                        </div>
                        <h3>{nextPost.frontmatter.title}</h3>
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`blog-layout__sidebar ${
              isOpenMenu === true ? "active" : ""
            }`}
          >
            <div class="card">
              <div class="card-wrapper">
                <div className="logo">
                  <Link to="/?utm_source=blog&utm_medium=right_banner&utm_campaign=promo">
                    <Logo />
                  </Link>
                </div>
                <div className="features">
                  <ul>
                    <li>Check Website and API.</li>
                    <li>Check SSL.</li>
                    <li>Monitor from 17 data centers.</li>
                    <li>
                      Get unlimited Slack, SMS, emails, discord alerts and more.
                    </li>
                    <li>Create public status page on your domain.</li>
                  </ul>
                </div>
                <div className="btn-action">
                  <Link
                    to={`/?utm_source=blog&utm_medium=right_banner&utm_campaign=promo`}
                  >
                    <div className="btn">Start monitoring</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetStartedBanner />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($path: String!, $next: String!, $previous: String!) {
    currentPost: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        category
        nav_title
        category_permalink
        date
        excerpt
        summarizer
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    prevPost: markdownRemark(frontmatter: { path: { eq: $previous } }) {
      html
      frontmatter {
        path
        title
        category
        nav_title
        category_permalink
        date
        excerpt
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    nextPost: markdownRemark(frontmatter: { path: { eq: $next } }) {
      html
      frontmatter {
        path
        title
        category
        nav_title
        category_permalink
        date
        excerpt
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

/**
 *
 * @param {*} html
 * @returns
 */
function stripHtml(html) {
  if (typeof document !== "undefined" && document) {
    // Create a new div element
    var temporalDivElement = document.createElement("div")
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || ""
  }
  return null
}
/**
 *
 * @param {*} text
 * @returns
 */
function readingTime(text) {
  const wpm = 225
  const words = text.trim().split(/\s+/).length
  const time = Math.ceil(words / wpm)
  return time
}

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")
